import { useEffect, useLayoutEffect, useState } from 'react'
import nl2br from 'react-nl2br'
import { useParams } from 'react-router-dom'

import * as amplitude from '@amplitude/analytics-browser'
import { Button, Header, Modal, RectangleSkeleton } from '@nexds/web'
import { useQuery } from '@tanstack/react-query'
import { capitalizeFirstLetterOfEachWord } from '@utils/formatString'

import { searchServices } from '@/services/searchService'

import { Info } from '@/components/Info'
import { useAppContext } from '@/context/AppContext'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { useDeviceType } from '@/hooks/useDeviceType'
import { DOCUMENT_BASE_TITLE } from '@/utils/constants'

import {
  ContactInfo,
  Session,
  NonPartnersContainer,
  Services,
  Tag,
  Title,
  Description,
  Icao,
  Name,
  Alert,
  SessionTitle,
  SkeletonSeparator,
  SkeletonHorizontal,
  SkeletonSession,
  InfoWrapper,
  ContactLink,
  ModalPhoneContainer
} from './NonPartners.styles'

export function NonPartners() {
  const { id } = useParams()
  const { appReady, isOnline } = useAppContext()
  const navigate = useAppNavigate()
  const deviceType = useDeviceType()

  const [optionsPhone, setOptionsPhone] = useState<string | null>(null)

  // Icao is the last part of the id, separated by a dash
  const idSplit = (id ?? '').split('-')
  const icao = idSplit[idSplit.length - 1].toUpperCase()

  const { fetchStatus, data: searchResults } = useQuery({
    queryKey: ['services', icao],
    queryFn: async () => searchServices(icao)
  })

  useEffect(() => {
    amplitude.track('NEXMARKET_SERVICE_ACCESSED', { service: id })
  }, [])

  const service = searchResults?.find((service) => service.id === id)
  const haveEmails = service?.emails.length !== 0 && service?.emails.map((email) => email !== '').includes(true)
  const havePhones = service?.phones.length !== 0 && service?.phones.map((phone) => phone !== '').includes(true)
  const isLoading = fetchStatus === 'fetching'

  useLayoutEffect(() => {
    document.title = service?.name ? `${service.name} - ${service.icao} - NexMarket` : DOCUMENT_BASE_TITLE
  }, [service])

  useEffect(() => {
    if (isOnline && service?.premium) {
      navigate(`/partner/${id ?? ''}`)
    }
  }, [id, navigate, isOnline, service])

  const handleReturn = () => {
    navigate(`/list?search=${icao}`)
  }

  return (
    <>
      <Header title="Informações da empresa" wrapperWidth={630} onReturn={handleReturn} />

      <NonPartnersContainer>
        {isLoading && !service && (
          <SkeletonSeparator>
            <SkeletonSession>
              <RectangleSkeleton width={150} height={18} />
            </SkeletonSession>
            <SkeletonSession>
              <SkeletonHorizontal>
                {[...Array(12)].map((_, index) => (
                  <RectangleSkeleton key={index} width={80} height={18} />
                ))}
              </SkeletonHorizontal>
            </SkeletonSession>
            <SkeletonSession>
              <RectangleSkeleton width={150} height={18} />
              <RectangleSkeleton width={50} height={18} />
              <RectangleSkeleton width={80} height={18} />
            </SkeletonSession>
            <SkeletonSession>
              <RectangleSkeleton width={50} height={18} />
              <RectangleSkeleton width={80} height={18} />
            </SkeletonSession>
            <SkeletonSession>
              <RectangleSkeleton width={300} height={32} />
            </SkeletonSession>
          </SkeletonSeparator>
        )}

        {service && (
          <>
            <Title>
              <Name>{service?.name ?? 'Nome da empresa'}</Name>
              <Icao>{service?.icao ?? 'ICAO'}</Icao>
            </Title>
            <Session>
              <SessionTitle>SERVIÇOS</SessionTitle>
              {service?.services.length !== 0 ? (
                <Services>
                  {service?.services.map((service, index) => (
                    <Tag key={index}>
                      <span>{capitalizeFirstLetterOfEachWord(service)}</span>
                    </Tag>
                  ))}
                </Services>
              ) : (
                <Alert>Sem informações de serviços disponíveis.</Alert>
              )}
            </Session>
            {!haveEmails && !havePhones && (
              <Session>
                <SessionTitle>CONTATO</SessionTitle>
                <Alert>Sem informações de contato disponíveis.</Alert>
              </Session>
            )}
            {havePhones && (
              <Session>
                <SessionTitle>TELEFONE</SessionTitle>
                {service?.phones.map((phone) => {
                  if (deviceType === 'desktop') {
                    return <ContactInfo key={phone}>{phone}</ContactInfo>
                  } else {
                    return (
                      <ContactLink
                        href="#"
                        key={phone}
                        onClick={() => {
                          setOptionsPhone(phone)
                        }}
                      >
                        <ContactInfo key={phone}>{phone}</ContactInfo>
                      </ContactLink>
                    )
                  }
                })}
              </Session>
            )}
            {haveEmails && (
              <Session>
                <SessionTitle>E-MAIL</SessionTitle>
                {service?.emails.map((email) => {
                  if (deviceType === 'desktop') {
                    return <ContactInfo key={email}>{email}</ContactInfo>
                  } else {
                    return (
                      <ContactLink href={`mailto:${email}`} key={email}>
                        <ContactInfo key={email}>{email}</ContactInfo>
                      </ContactLink>
                    )
                  }
                })}
              </Session>
            )}
            <Session>
              <SessionTitle>DESCRIÇÃO</SessionTitle>
              {service?.description ? (
                <Description>{nl2br(service.description)}</Description>
              ) : (
                <Alert>Sem informações de descrição disponíveis.</Alert>
              )}
            </Session>
            <InfoWrapper>
              <Button
                label="Sugerir alteração"
                variant="outline"
                color="secondary"
                size="sm"
                onPress={() => {
                  amplitude.track('NEXMARKET_SERVICE_CHANGE-SUGGESTION_CLICKED', { service: id })
                  window.open(
                    `https://docs.google.com/forms/d/e/1FAIpQLScPZai-X5_-p6sZedHPo8M9JwYOHVFI4RNb2bAcYV181doFhQ/viewform?entry.548653472=${
                      id ?? ''
                    }`,
                    '_blank'
                  )
                }}
              />
            </InfoWrapper>
          </>
        )}

        {fetchStatus === 'paused' && appReady && !service && (
          <InfoWrapper>
            <Info icon="warning" infoText="Ocorreu um erro na sua busca, verifique a conexão com a internet." />
          </InfoWrapper>
        )}

        {fetchStatus === 'idle' && appReady && !service && (
          <InfoWrapper>
            <Info icon="warning" infoText="Não temos informação sobre este serviço. Realize outra busca." />
          </InfoWrapper>
        )}
      </NonPartnersContainer>

      <Modal
        isOpen={!!optionsPhone}
        onClose={() => setOptionsPhone(null)}
        title="Telefone"
        description={optionsPhone ?? ''}
        maxWidth={400}
        maxHeight={300}
      >
        <ModalPhoneContainer>
          <Button
            label="Ligar"
            color="primary"
            variant="filled"
            size="sm"
            fullWidth
            onPress={() => {
              const phone = `+55${
                optionsPhone
                  ?.normalize?.('NFD')
                  ?.replace?.(/[\u0300-\u036f]/g, '')
                  ?.replace(/\(|\)|\s|-|[A-Z]|[a-z]/g, '') ?? ''
              }`

              amplitude.track('NEXMARKET_SERVICE_PHONE-CALL_SELECTED', { service: id, phone })
              window.open(`tel:${phone}`, '_blank')
            }}
          />
          <Button
            label="WhatsApp"
            color="primary"
            variant="filled"
            size="sm"
            fullWidth
            onPress={() => {
              const phone = `%2B55${
                optionsPhone
                  ?.normalize?.('NFD')
                  ?.replace?.(/[\u0300-\u036f]/g, '')
                  ?.replace(/\(|\)|\s|-|[A-Z]|[a-z]/g, '') ?? ''
              }`

              amplitude.track('NEXMARKET_SERVICE_PHONE-WHATSAPP_SELECTED', { service: id, phone })
              window.open(
                `https://api.whatsapp.com/send/?phone=${phone}&text=Ol%C3%A1%2C%20Vim%20pelo%20NexMarket%20e%20gostaria%20de%20saber%20mais%20sobre%20os%20seus%20servi%C3%A7os`,
                '_blank'
              )
            }}
          />
          <Button
            label="Cancelar"
            color="secondary"
            variant="outline"
            size="sm"
            fullWidth
            onPress={() => setOptionsPhone(null)}
          />
        </ModalPhoneContainer>
      </Modal>
    </>
  )
}
